import { IHeroProps } from "../components/Hero";
import { SurvivalLogo } from "../components/SurvivalLogo";

import { Kobo } from "../components/Kobo";
import { Readmoo } from "../components/ReadmooLogo";
import { BookWalker } from "../components/BookWalker";
import { AppleBooks } from "../components/AppleBooks";
import { GooglePlay } from "../components/GooglePlay";

import handbookBackground from "../assets/handbookBackground.webp";
import handbookForeground from "../assets/handbookForeground.webp";
import audiobookBackground from "../assets/audiobookBackground.webp";
import audiobookForeground from "../assets/audiobookForeground.webp";
import { Kindle } from "../components/Kindle";
import { Booth } from "../components/Booth";

export const handbookHero: IHeroProps = {
  LogoComponent: SurvivalLogo,
  title: "當代學生生存手冊",
  coverSrc: handbookForeground,
  backgroundSrc: handbookBackground,
  description: "放下分數，重新理解學校、教育和我們的未來",
  sources: [
    {
      Component: Readmoo,
      title: "Readmoo",
      src: "https://readmoo.com/book/2103050900001",
    },
    {
      Component: Kobo,
      title: "Kobo",
      src: "https://www.kobo.com/tw/zh/ebook/t3pht0XnMTmzqp4e4H50mw",
    },
    {
      Component: Kindle,
      title: "Amazon Kindle",
      src: "https://www.amazon.com/%E7%95%B6%E4%BB%A3%E5%AD%B8%E7%94%9F%E7%94%9F%E5%AD%98%E6%89%8B%E5%86%8A-Traditional-Chinese-%E9%8A%85%E9%91%BC-%E8%B2%9E%E9%9B%84-ebook/dp/B0CPWJ648F/",
    },
    {
      Component: Booth,
      title: "Booth",
      src: "https://m-b.booth.pm/items/5322274",
    },
    {
      Component: AppleBooks,
      title: "Apple Books",
      src: "#",
    },
    {
      Component: GooglePlay,
      title: "Google Play",
      src: "https://play.google.com/store/books/details?id=MVfqEAAAQBAJ",
    },
  ],
};

export const audiobookHero: IHeroProps = {
  LogoComponent: SurvivalLogo,
  title: "當代學生生存手冊 · 有聲書",
  coverSrc: audiobookForeground,
  backgroundSrc: audiobookBackground,
  description: "專為喜好聽覺輸入的你準備的有聲書",
  sources: [
    {
      Component: Readmoo,
      title: "Readmoo",
      src: "#",
    },
    {
      Component: BookWalker,
      title: "Book Walker",
      src: "#",
    },
    {
      Component: Kobo,
      title: "Kobo",
      src: "#",
    },
    {
      Component: AppleBooks,
      title: "Apple Books",
      src: "#",
    },
    {
      Component: GooglePlay,
      title: "Google Play",
      src: "#",
    },
  ],
};
