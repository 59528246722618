import React from "react";

// import Slider from "react-slick";

import {
  makeStyles,
  shorthands,
  mergeClasses,
  tokens,
} from "@fluentui/react-components";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { Hero } from "../components/Hero";
import { CommentCard } from "../components/CommentCard";
import { ProductCard } from "../components/ProductCard";
import { useScrollRestoration } from "../hooks/useScrollRestoration";
import {
  appProduct,
  bookProduct,
  audioBookProduct,
} from "../constants/products";

import { Page1 } from "../components/Page1";
import { Page2 } from "../components/Page2";
import { Page3 } from "../components/Page3";
import { Page4 } from "../components/Page4";
import { Page5 } from "../components/Page5";
import { Page7 } from "../components/Page7";

import { Page1V } from "../components/Page1V";
import { Page2V } from "../components/Page2V";
import { Page3V } from "../components/Page3V";
import { Page4V } from "../components/Page4V";
import { Page7V } from "../components/Page7V";

import { handbookHero } from "../constants/hero";

import page7Background from "../assets/wish.webp";

const useStyles = makeStyles({
  section: {
    height: `100vh`,
    scrollSnapType: "y proximity",
  },
  alternativeColor: {
    backgroundColor: "#1c1c1c",
  },
  flexSection: {
    minHeight: `100vh`,
    scrollSnapType: "y proximity",
  },
  svgSection: {
    ...shorthands.padding("5vw"),
    boxSizing: "border-box",
  },
  v: {
    "@media (orientation: landscape)": {
      display: "none",
    },
  },
  h: {
    "@media (orientation: portrait)": {
      display: "none",
    },
  },
  hero: {
    width: "100%",
    height: "100vh",
    overflowX: "clip",
    boxSizing: "border-box",
    position: "relative",
  },
  dots: {
    bottom: "28px",
    width: "100%",
    ...shorthands.margin(0),
    ...shorthands.padding(0),
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    display: "flex !important",
    listStyleType: "none",

    "> li": {
      lineHeight: 0,
      opacity: 0.5,
    },

    "> .slick-active": {
      opacity: 1,
    },
    "> li > button": {
      ...shorthands.border(0),
      color: "transparent",
      backgroundColor: "transparent",
      position: "relative",

      "&::before": {
        content: "''",
        top: "50%",
        left: "50%",
        width: "8px",
        height: "8px",
        ...shorthands.borderRadius("50%"),
        backgroundColor: tokens.colorNeutralForeground1,
        transform: "translate(-50%, -50%)",
        position: "absolute",
      },
    },
  },
  page5: {
    height: "auto",
    minHeight: "auto",
  },
  page5Image: {
    width: "48vw",
    minWidth: "300px",
    ...shorthands.margin("0", "auto"),
    display: "block",
    marginBottom: "36px",
  },
  commentList: {},
  productList: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media screen and (max-width: 1200px)": {
      flexDirection: "column",
    },
  },
  product: {
    "@media screen and (max-width: 1200px)": {
      maxWidth: "800px",
    },
  },
  page6: {
    "@media screen and (max-width: 1200px)": {
      height: "max-content",
    },
  },
  page7: {
    backgroundImage: `url(${page7Background})`,
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundSize: "cover",
  },
});

const App: React.FC = () => {
  const styles = useStyles();

  useScrollRestoration();

  return (
    <div>
      {/* <Slider
        className={styles.hero}
        dots
        infinite
        autoplay
        dotsClass={styles.dots}
        arrows={false}
      > */}
      <Hero {...handbookHero} />
      {/* <Hero {...audiobookHero} /> */}
      {/* </Slider> */}
      <section
        id="section_1"
        className={mergeClasses(
          styles.section,
          styles.svgSection,
          styles.alternativeColor
        )}
      >
        <Page1 className={styles.h} />
        <Page1V className={styles.v} />
      </section>
      <section
        id="section_2"
        className={mergeClasses(styles.section, styles.svgSection)}
      >
        <Page2 className={styles.h} />
        <Page2V className={styles.v} />
      </section>
      <section
        id="section_3"
        className={mergeClasses(
          styles.section,
          styles.svgSection,
          styles.alternativeColor
        )}
      >
        <Page3 className={styles.h} />
        <Page3V className={styles.v} />
      </section>
      <section
        id="section_4"
        className={mergeClasses(styles.section, styles.svgSection)}
      >
        <Page4 className={styles.h} />
        <Page4V className={styles.v} />
      </section>
      <section
        id="section_5"
        className={mergeClasses(
          styles.flexSection,
          styles.svgSection,
          styles.alternativeColor,
          styles.page5
        )}
      >
        <Page5 className={styles.page5Image} />
        <div className={styles.commentList}>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 200: 1, 320: 2, 900: 3, 1200: 4 }}
          >
            <Masonry>
              <CommentCard>
                從 Podcast
                放出就在等文字稿了！不管是學習方式還是更深層次的思想上都很受啟發，甚至準備推給認識的老師與家長，謝謝！
              </CommentCard>
              <CommentCard>感謝，我要開始瘋狂轉發了，真的很受用。</CommentCard>
              <CommentCard>
                寫得真的很棒，不由自主地完整地看了兩遍。很多學習上的思考我也非常地認同。由衷地希望筆者能夠更上一層樓，未來能當一個教育學家或是一校之長。
              </CommentCard>
              <CommentCard>
                剛剛閱讀完您的部落格文章，我不得不說這篇文章給我留下了深刻的印象。您的寫作風格引人入勝，文章思想深刻，您分享的見解對我來說也有巨大的益處。閱讀完這篇文章後，我加深了自己對學習的認知。感謝您分享您的智慧和知識，我真的從中獲益頗豐。
              </CommentCard>
              <CommentCard>
                我是高三狗，受益匪淺，希望自己能更上一層樓，感謝作者。
              </CommentCard>
              <CommentCard>
                寫作文那段太形象，我過了三十歲才明白，小時候我寫不出作文，那才是正常，
                拿著一個完全莫名其妙的題目，既無興趣，也無體驗，更別說有什麼感想了，寫得好才見鬼了。
              </CommentCard>
              <CommentCard>
                作者這樣清晰有脈絡的整理，讓我理清了很多，受益匪淺。非常感謝作者的文章，希望以後能看到更多分享。
              </CommentCard>
              <CommentCard>
                謝謝大佬，記了筆記，現在就是希望這些筆記能夠直接內化為行動。
              </CommentCard>
              <CommentCard>
                再次拜讀，真的是受益匪淺。一些思路確實能夠幫助到我，甚至我有些惱火你沒有早些寫這些，不然我孩子的成績可能更好一些。我更希望有一些老師，能夠看到這篇文章。
              </CommentCard>
              <CommentCard>
                寫得真好哇，作者對學習這件事真的理解很深。
              </CommentCard>
              <CommentCard>
                受益匪淺，正是帶娃一起學習的階段，作者講述的很多內容，平日也有想到的，體會到的，沒有這麼系統的輸入輸出。真幸運，有作者寫了這些。
              </CommentCard>
              <CommentCard>
                高三生，看完後有了一些新的思考的認知，阿里嘎多。
              </CommentCard>
              <CommentCard>
                給人一種，「哦原來我之前遇到過的問題是不是也是這樣」的感覺。
              </CommentCard>
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </section>
      <ax-reveal-bound>
        <section
          id="section_6"
          className={mergeClasses(
            styles.section,
            styles.svgSection,
            styles.page6
          )}
        >
          <div className={styles.productList}>
            <ProductCard className={styles.product} {...bookProduct} />
            <ProductCard className={styles.product} {...appProduct} />
            <ProductCard className={styles.product} {...audioBookProduct} />
          </div>
        </section>
      </ax-reveal-bound>
      <section
        id="section_7"
        className={mergeClasses(
          styles.section,
          styles.svgSection,
          styles.page7
        )}
      >
        <Page7 className={styles.h} />
        <Page7V className={styles.v} />
      </section>
    </div>
  );
};

export default App;
