import * as React from "react";

import { makeStyles, tokens, shorthands } from "@fluentui/react-components";

const useStyles = makeStyles({
  main: {
    backgroundColor: tokens.colorNeutralForeground1,
    color: tokens.colorNeutralBackground1,
    lineHeight: '1.8em',
    ...shorthands.padding("0.5em"),
    ...shorthands.margin("0.5em"),

    "@media (min-width: 768px)": {
      fontSize: `calc(1rem + ((1vw - 7.68px) * 0.5208))`,
    },

    "@media (min-width: 1920px)": {
      fontSize: "24px",
    },
  },
});

export const CommentCard: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const styles = useStyles();
  return <div className={styles.main}>{children}</div>;
};
