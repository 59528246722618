import React from "react";

import {
  Text,
  tokens,
  makeStyles,
  shorthands,
  mergeClasses,
} from "@fluentui/react-components";
import { useScrollRestoration } from "../hooks/useScrollRestoration";
import { RotatingImage, useRotateImage } from "./RotatingImage";

const useStyles = makeStyles({
  section: {
    height: `100vh`,
    scrollSnapType: "y proximity",
  },
  disabled: {
    filter: "grayscale(1)",
    cursor: "not-allowed",
  },
  header: {
    paddingLeft: `calc((100vh - 64px) * 0.075)`,
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundSize: "cover",
    boxSizing: "border-box",
    position: "relative",
    display: "flex",
    alignItems: "center",
    color: tokens.colorNeutralForeground1,

    "@media screen and (max-width: 860px)": {
      paddingLeft: 0,
      flexDirection: "column",
      justifyContent: "center",
    },
  },

  coverImage: {
    width: `calc((100vw - 64px) * 0.4)`,

    ...shorthands.borderRadius("20px"),
    position: "relative",

    "@media screen and (max-width: 860px)": {
      width: `80vw`,
      maxWidth: "360px",
    },
  },

  mainContent: {
    ...shorthands.padding(0, "80px"),
    flexGrow: 1,

    "@media screen and (max-width: 940px)": {
      ...shorthands.padding(0, "40px", 0, "80px"),
    },

    "@media screen and (max-width: 860px)": {
      width: "80vw",
      ...shorthands.padding(
        "60px !important",
        "0 !important",
        0,
        "0 !important"
      ),
      textAlign: "center",
      flexGrow: 0,
    },
  },
  title: {
    width: "18vw",

    "@media screen and (max-width: 860px)": {
      width: `40vw`,
      maxWidth: "200px",
    },
  },
  decorationBar: {
    width: "80px",
    height: "2px",
    backgroundColor: "#ffac00",
    ...shorthands.margin("24px", "0"),

    "@media screen and (max-width: 860px)": {
      ...shorthands.margin("24px", "auto"),
    },
  },
  description: {
    "@media screen and (max-width: 1000px)": {
      fontSize: "16px",
    },
  },
  storeIcon: {
    height: "60px",

    "@media screen and (max-width: 1200px)": {
      height: "48px",
    },
  },
  sources: {
    maxWidth: "800px",
    ...shorthands.padding("48px", "0", "0", "0"),
    listStyleType: "none",

    "@media screen and (max-width: 1200px)": {
      ...shorthands.padding("26px", "0", "0", "0"),
    },
  },
  source: {
    display: "inline",
    ...shorthands.padding("2px"),
  },
  sourceLink: {
    "> *": {
      lineHeight: 0,
      ...shorthands.borderRadius("10px"),
      ...shorthands.overflow("clip"),
      "--acrylic-tint-color": "#070f0f",
    },

    "> * > *": {
      ...shorthands.borderRadius("10px"),
      boxShadow: "inset 0 0 0 1px #767676",
      "--reveal-color": "white",
      "--reveal-opacity": "0.4",
      "--reveal-border-decoration-type": "round",
      "--reveal-border-decoration-radius": "10px",
    },
  },
});

export interface IHeroProps {
  LogoComponent: React.MemoExoticComponent<
    React.FC<React.SVGProps<SVGSVGElement>>
  >;
  title: string;
  coverSrc: string;
  backgroundSrc: string;
  description: string;
  sources: ISource[];
}

export interface ISource {
  Component: React.MemoExoticComponent<React.FC<React.SVGProps<SVGSVGElement>>>;
  title: string;
  src: string;
}

export const Hero: React.FC<IHeroProps> = ({
  LogoComponent,
  title,
  coverSrc,
  backgroundSrc,
  description,
  sources,
}) => {
  const styles = useStyles();

  useScrollRestoration();

  const {
    containerRef,
    handleMouseEnterContainer,
    handleMouseLeaveContainer,
    cardStyle,
    glowStyle,
  } = useRotateImage();

  const headerStyle = React.useMemo(
    () => ({
      backgroundImage: `linear-gradient(90deg, rgba(66,114,114,0) 0%, rgba(36,79,79,0.7) 100%), url(${backgroundSrc})`,
    }),
    [backgroundSrc]
  );

  return (
    <ax-reveal-bound>
      <section
        ref={containerRef}
        className={mergeClasses(styles.section, styles.header)}
        style={headerStyle}
        onMouseEnter={handleMouseEnterContainer}
        onMouseLeave={handleMouseLeaveContainer}
      >
        <RotatingImage
          src={coverSrc}
          className={styles.coverImage}
          cardStyle={cardStyle}
          glowStyle={glowStyle}
        />
        <div className={styles.mainContent}>
          <Text as="h1">
            <LogoComponent className={styles.title} xlinkTitle={title} />
          </Text>
          <div className={styles.decorationBar}></div>
          <Text size={500} className={styles.description}>
            {description}
          </Text>
          <ul className={styles.sources}>
            {sources.map(({ Component, title, src }, index) => (
              <li className={styles.source} key={index}>
                <a href={src} className={mergeClasses(src === "#" && styles.disabled, styles.sourceLink)}>
                  <ax-acrylic>
                    <ax-reveal>
                      <Component
                        xlinkTitle={title}
                        className={styles.storeIcon}
                      />
                    </ax-reveal>
                  </ax-acrylic>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </ax-reveal-bound>
  );
};
