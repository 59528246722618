import React from "react";

import {
  Text,
  tokens,
  makeStyles,
  shorthands,
  mergeClasses,
} from "@fluentui/react-components";

import { TelegramQR } from "../components/TelegramQR";
import { ProjectLogo } from "../components/ProjectLogo";
import { useScrollRestoration } from "../hooks/useScrollRestoration";
import { RotatingImage, useRotateImage } from "../components/RotatingImage";

import background from "../assets/handbookBackground.webp";

const useStyles = makeStyles({
  section: {
    height: `100vh`,
    scrollSnapType: "y proximity",
  },

  header: {
    ...shorthands.padding(0, "32px"),
    backgroundImage: `linear-gradient(90deg, rgba(66,114,114,0) 0%, rgba(36,79,79,0.7) 100%), url(${background})`,
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundSize: "cover",
    boxSizing: "border-box",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: tokens.colorNeutralForeground1,

    "@media screen and (max-width: 1290px)": {
      flexDirection: "column",
    },
  },

  QRCodeContainer: {
    width: `calc((100vw - 64px) * 0.2)`,
    height: `calc((100vw - 64px) * 0.2)`,
    ...shorthands.borderRadius("10px"),
    backgroundColor: tokens.colorNeutralForeground1,
    color: tokens.colorNeutralBackground1,

    "@media screen and (max-width: 1290px)": {
      width: `calc((100vw - 64px) * 0.18)`,
      height: `calc((100vw - 64px) * 0.18)`,
    },
  },

  QRCode: {
    width: "100%",
    height: "100%",
  },

  main: {
    marginLeft: "6vw",

    "@media screen and (max-width: 1290px)": {
      marginLeft: 0,
      marginTop: "48px",
      textAlign: "center",
    },
  },

  logo: {
    maxWidth: "360px",
    marginBottom: "32px",

    "@media screen and (max-width: 1290px)": {
      maxWidth: "240px",
      display: "none",
    },
  },

  content: {
    maxWidth: "600px",
    lineHeight: "2.5em",
  },

  a: {
    "&:link": {
      color: "var(--colorNeutralForeground1)",
      textDecorationLine: "none",
    },
    "&:visited": {
      color: "var(--colorNeutralForeground1)",
      textDecorationLine: "none",
    },
  },
});

export const ReaderService: React.FC = () => {
  const styles = useStyles();

  const {
    containerRef,
    handleMouseEnterContainer,
    handleMouseLeaveContainer,
    cardStyle,
    glowStyle,
  } = useRotateImage();

  useScrollRestoration();

  return (
    <section
      ref={containerRef}
      className={mergeClasses(styles.section, styles.header)}
      onMouseEnter={handleMouseEnterContainer}
      onMouseLeave={handleMouseLeaveContainer}
    >
      <a href="https://t.me/+GN5t94JmXtQwNDNh">
        <RotatingImage
          className={styles.QRCodeContainer}
          cardStyle={cardStyle}
          glowStyle={glowStyle}
        >
          <TelegramQR className={styles.QRCode} />
        </RotatingImage>
      </a>
      <div className={styles.main}>
        <ProjectLogo className={styles.logo} />
        <div className={styles.content}>
          <Text size={500}>
            <a href="https://t.me/+GN5t94JmXtQwNDNh" className={styles.a}>
              感謝您支持正版內容，我們為您準備了一個 Telegram
              討論群組，以供您直接參與討論。
            </a>
          </Text>
        </div>
      </div>
    </section>
  );
};
