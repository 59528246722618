import React from "react";

import {
  tokens,
  makeStyles,
  shorthands,
  mergeClasses,
} from "@fluentui/react-components";

import { PyoTitle } from "../components/PyoTitle";
import { ProductCard } from "../components/ProductCard";
import { useScrollRestoration } from "../hooks/useScrollRestoration";
import { latexFile, pdfProduct } from "../constants/pyoService";

import background from "../assets/handbookBackground.webp";
import "../styles/book.css";
import { LaTeX } from "../components/LaTeX";

const useStyles = makeStyles({
  section: {
    minHeight: `100vh`,
    scrollSnapType: "y proximity",
  },

  header: {
    ...shorthands.padding("25vh", "32px"),
    backgroundImage: `linear-gradient(90deg, rgba(66,114,114,0) 0%, rgba(36,79,79,0.7) 100%), url(${background})`,
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    boxSizing: "border-box",
    position: "relative",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    color: tokens.colorNeutralForeground1,
    overflowX: "clip",

    "@media (max-width: 1200px)": {
      ...shorthands.padding("20vh", "20px"),
      flexDirection: "column-reverse",
      alignItems: "center",
    },

    "@media (max-width: 800px)": {
      ...shorthands.padding("10vh", "12px"),
    },
  },

  bookContainer: {
    top: "120px",
    width: "20vw",
    maxWidth: "340px",
    position: "sticky",
    transform: "scaleX(-1)",

    "@media (max-width: 1200px)": {
      position: "initial",
      width: "25vw",
      maxWidth: "initial",
    },

    "@media (max-width: 800px)": {
      width: "35vw",
    },

    "::before": {
      content: "''",
      display: "block",
      width: "100%",
      paddingTop: `calc((1749 / 1241) * 100%)`,
    },
  },

  book: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },

  main: {
    width: "60vw",
    lineHeight: "2em",
    paddingRight: "8vw",
    boxSizing: "border-box",

    "@media (max-width: 768px)": {
      width: "80vw",
    },

    "@media (min-width: 768px)": {
      fontSize: `calc(1rem + ((1vw - 7.68px) * 0.5208))`,
    },

    "@media (min-width: 1920px)": {
      fontSize: "24px",
    },

    "@media (max-width: 1200px)": {
      paddingRight: 0,
    },
  },

  content: {
    maxWidth: "600px",
    lineHeight: "2.5em",
  },

  pyoTitle: {
    maxWidth: "300px",

    "@media (max-width: 1200px)": {
      ...shorthands.margin("20vh", "auto", "40px", "auto"),
      display: "block",
    },

    "@media (max-width: 800px)": {
      maxWidth: "240px",
      ...shorthands.margin("14vh", "auto", "40px", "auto"),
    },
  },

  productList: {
    height: "100%",
    ...shorthands.padding("1em", 0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media (max-width: 1200px)": {
      flexDirection: "column",
    },
  },
  qa: {
    textShadow: "0 0 20px #1f292a",
  },
  q: {
    marginBlockStart: "2em",
    marginBlockEnd: "0",
  },
  a: {
    marginBlockStart: "0.5em",
  },
});

export const Pyo: React.FC = () => {
  const styles = useStyles();

  useScrollRestoration();

  return (
    <section className={mergeClasses(styles.section, styles.header)}>
      <div className={styles.main}>
        <div>
          <PyoTitle className={styles.pyoTitle} />
        </div>
        <p className={styles.qa}>
          我們深知很多讀者偏好紙質書籍，但各個國家的出版、列印和倉儲費用實在難以負擔。
          因此我們選擇了一種更為靈活的販售模式，即提供精排版的數位印刷原稿，
          您可按照自己的偏好尋找數位印刷商鋪進行印刷作業。
        </p>
        <ax-reveal-bound>
          <div className={styles.productList}>
            <ProductCard {...pdfProduct} />
            <ProductCard {...latexFile} />
          </div>
        </ax-reveal-bound>
        <div className={styles.qa}>
          <h2>常見問題</h2>
          <h3 className={styles.q}>自印刷授權的定價模型是怎樣的？</h3>
          <p className={styles.a}>
            您所購買的數位產品包含了原始檔案和印刷授權兩部分。 PDF 版本自帶 1
            份書籍的印刷授權，
            <LaTeX /> 版本自帶 3 份書籍的印刷授權。
            您可根據需要自由選擇額外的印刷數量，每本額外的印刷授權售價為 2
            美金。
          </p>
          <h3 className={styles.q}>我能否自行販售印刷版本的書籍？</h3>
          <p className={styles.a}>
            可以。只要您提前購買過足夠量的印刷授權，則可以自由販售紙本書籍。
            我們不對您的售價施加任何額外限制。
          </p>
          <h3 className={styles.q}>我能否分享自己設計的排印設計版本？</h3>
          <p className={styles.a}>
            不可以。無論您購買的是哪種授權、是否修改過其內容，都不得以任何形式分發本書的
            <LaTeX /> 工程檔案以及 PDF 檔案。分享 <LaTeX />{" "}
            的任何編譯產物也是不被允許的。
          </p>
          <h3 className={styles.q}>我能否對內容進行再創作？</h3>
          <p className={styles.a}>
            不可以。我們僅授權您對版式和封面設計進行調整，對內容和標題的刪改則不在授權範圍之列。
          </p>
          <h3 className={styles.q}>我能否在初次購買后追加額外的印刷授權？</h3>
          <p className={styles.a}>
            可以。您無需重複為數位文件授權，只需要為額外的授權數量付費即可。
          </p>
          <h3 className={styles.q}>我能否印製 EPUB 版本的書籍？</h3>
          <p className={styles.a}>
            不可以。所有印刷授權均建立在您已經購買過 PDF 或 <LaTeX />{" "}
            稿件的基礎上。
          </p>
          <h3 className={styles.q}>此授權是否適用於數位化版本的書籍？</h3>
          <p className={styles.a}>
            不適用。此授權不適用於 EPUB 版本及 PDF
            版本的電子書，也不適用於掃描版本的紙質書籍。
          </p>
        </div>
      </div>
      <div className={styles.bookContainer}>
        <figure className={mergeClasses("book", styles.book)}>
          <ul className="hardcover_front">
            <li>
              <div className="coverDesign"></div>
            </li>
            <li></li>
          </ul>

          <ul className="page">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>

          <ul className="hardcover_back">
            <li></li>
            <li></li>
          </ul>
          <ul className="book_spine">
            <li></li>
            <li></li>
          </ul>
        </figure>
      </div>
    </section>
  );
};
