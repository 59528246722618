import React from "react";
import ReactDOM from "react-dom/client";

import { FluentProvider } from "@fluentui/react-components";
import { register as registerAcrylic } from "@ax-design/acrylic";
import { register as registerRevealHighlight } from "@ax-design/reveal-highlight";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import App from "./routes/_index";
import { Pyo } from "./routes/pyo";
import { ReaderService } from "./routes/readerService";
import { survivalDarkTheme } from "./constants/theme";

import "./index.css";

registerAcrylic();
registerRevealHighlight({
  compat: true,
  borderDetectionMode: "experimentalAutoFit",
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/readerService",
    element: <ReaderService />,
  },
  {
    path: "/rs",
    element: <ReaderService />,
  },
  {
    path: "/pyo",
    element: <Pyo />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <FluentProvider theme={survivalDarkTheme}>
    <React.StrictMode>
      <ax-reveal-provider>
        <RouterProvider router={router} />
      </ax-reveal-provider>
    </React.StrictMode>
  </FluentProvider>
);
