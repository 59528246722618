import * as React from "react";
import { SVGProps, memo } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
      fill: "currentcolor",
    }}
    viewBox="0 0 1080 1920"
    {...props}
  >
    <path
      d="M.385-.53v-.167l.089-.021c.023.008.041.01.055.007.001.062.004.123.01.181H.385Zm.583.292L.959-.241a1.173 1.173 0 0 1-.07.128c-.01.016-.018.016-.033.003a.376.376 0 0 1-.081-.088.967.967 0 0 0 .133-.193c.024.003.034-.004.04-.015L.781-.482a.95.95 0 0 1-.066.158.934.934 0 0 1-.036-.178h.272c.014 0 .025-.005.028-.016C.936-.553.87-.6.852-.613c.074-.022.094-.16-.13-.188l-.008.005a.289.289 0 0 1 .062.164.086.086 0 0 0 .068.021L.776-.53h-.1A2.456 2.456 0 0 1 .67-.79c.026-.004.034-.016.035-.029L.527-.836l.001.101-.131-.114c-.08.058-.241.14-.372.186l.002.01a3.21 3.21 0 0 0 .216-.02v.143H.026l.008.028h.209v.152a7.276 7.276 0 0 1-.218.025l.061.163a.046.046 0 0 0 .03-.025l.127-.051v.158c0 .012-.005.02-.021.02-.025 0-.139-.007-.139-.007v.012c.059.01.081.026.098.047s.024.056.026.102C.361.083.385.018.385-.075v-.224C.453-.33.507-.357.549-.38L.548-.391l-.163.023v-.134h.157c.013.118.037.227.082.323a.89.89 0 0 1-.25.225l.007.011a.87.87 0 0 0 .288-.155c.03.045.065.086.108.123.048.041.14.085.192.032C.988.037.983.001.944-.065l.024-.173Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 777.924 640.443)"
    />
    <path
      d="M.431-.465v-.134h.051v.134H.431Zm.051-.283v.121H.431v-.121h.051ZM.473-.776H.434L.296-.834v.026L.144-.855a1.95 1.95 0 0 1-.134.526l.012.007a.62.62 0 0 0 .077-.084v.501h.024C.173.095.228.069.229.06v-.583c.019-.003.028-.01.032-.019L.198-.565C.23-.63.257-.701.28-.779l.016-.002v.876h.023C.38.095.431.062.431.044v-.481h.051v.057h.019c.036 0 .09-.022.091-.029v-.326c.016-.003.027-.01.032-.017L.523-.828l-.05.052Zm.268.311v-.134h.053v.134H.741Zm.053-.283v.121H.741v-.121h.053Zm.052-.099-.062.071H.745L.63-.822v.439h.016c.048 0 .095-.025.095-.035v-.019h.053v.361c0 .013-.004.02-.021.02-.022 0-.127-.006-.127-.006v.013c.053.01.073.025.09.046.016.019.022.053.025.098C.912.081.933.031.933-.061v-.665a.064.064 0 0 0 .039-.021l-.126-.1Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 777.924 791.317)"
    />
    <path
      d="M.156-.224a.137.137 0 0 1-.061.125c-.038.017-.065.05-.053.095a.075.075 0 0 0 .109.04C.207.005.236-.092.167-.225l-.011.001ZM.774-.74v.109H.636V-.74h.138Zm-.138.243v-.106h.138v.106H.636Zm0 .142v-.114h.138v.114H.636Zm-.185.087c.03.045.056.11.058.171.104.088.22-.091.015-.16.056-.001.112-.031.112-.045v-.025h.138v.064h.024c.048 0 .118-.027.119-.035v-.419a.074.074 0 0 0 .04-.022l-.13-.099-.063.07H.64L.5-.823v.559C.487-.268.474-.27.459-.273l-.008.005ZM.335-.546a.327.327 0 0 1 .033.126c.104.088.223-.11-.033-.165v-.072h.138c.014 0 .024-.005.027-.016C.461-.714.392-.775.392-.775l-.057.084v-.125C.359-.82.366-.829.368-.841l-.17-.016v.172H.034l.008.028h.119a.86.86 0 0 1-.143.367l.01.01a.71.71 0 0 0 .17-.151v.186h.025c.051 0 .112-.027.112-.038v-.263Zm.405.363H.731a.63.63 0 0 1-.057.109c-.009.01-.016.013-.033.014l-.083.001H.443c-.032 0-.036-.004-.036-.017v-.127c.021-.003.03-.012.032-.025L.262-.242v.193c0 .091.03.111.155.111H.54c.199 0 .254-.018.254-.078 0-.025-.01-.041-.051-.056L.74-.183Zm0-.048c.046.059.08.146.081.225.126.108.257-.154-.073-.23L.74-.231Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 777.924 942.195)"
    />
    <path
      d="M.591-.254a.301.301 0 0 1-.079.134 3.857 3.857 0 0 0-.131-.008C.406-.165.434-.211.46-.254h.131ZM.277-.606h.058v.17H.277v-.17Zm.195-.148h.061v.12H.472v-.12Zm.262.148v.17H.672v-.17h.062Zm-.262.17v-.17h.061v.17H.472Zm.3.154h-.02l.007-.037c.024-.003.035-.014.037-.028L.607-.362a.672.672 0 0 1-.01.08h-.12l.038-.066c.033.003.043-.006.047-.018L.437-.408h.297v.039h.025c.044 0 .116-.022.117-.029v-.185c.021-.005.034-.014.04-.022l-.13-.097-.062.068H.672v-.12h.267c.014 0 .026-.005.029-.016C.917-.813.833-.876.833-.876l-.074.094H.046l.008.028h.281v.12H.287L.137-.69v.328h.019c.057 0 .121-.03.121-.042v-.004h.099a1.514 1.514 0 0 1-.064.126H.026l.008.028h.262a1.5 1.5 0 0 1-.087.14c.082.015.159.032.23.052a1.331 1.331 0 0 1-.359.14l.004.015c.226-.03.373-.071.47-.12.107.036.192.076.25.112.115.05.275-.115-.143-.186a.323.323 0 0 0 .084-.153h.202c.015 0 .026-.005.029-.016C.926-.312.844-.372.844-.372l-.072.09Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 777.924 1093.072)"
    />
    <path
      d="M.117-.197c.007.044-.025.098-.047.12C.037-.055.02-.02.037.017c.02.042.082.046.106.016C.175-.009.176-.09.13-.197H.117Zm.07-.009a.52.52 0 0 1-.004.188c.076.1.213-.061.016-.191l-.012.003Zm.1-.013a.34.34 0 0 1 .032.167C.403.035.522-.138.297-.224l-.01.005Zm.285.207v-.251h.175v.251H.572Zm.165-.279h-.16L.436-.346v.44h.02c.056 0 .116-.03.116-.042V.016h.175v.063H.77c.043 0 .108-.022.11-.03v-.29a.078.078 0 0 0 .04-.022L.796-.356l-.059.065ZM.672-.452H.478l.008.028h.331c.014 0 .025-.005.028-.016a3 3 0 0 0-.111-.1l-.062.088Zm.037-.332c.024.001.036-.008.041-.02L.57-.862a1.071 1.071 0 0 1-.209.464l.005.005a.33.33 0 0 0-.073-.036l-.01.005a.533.533 0 0 1 .031.083L.138-.33a1.3 1.3 0 0 0 .27-.231c.02.005.033-.001.038-.01l-.14-.096a.616.616 0 0 1-.048.097l-.14.001C.188-.62.267-.7.313-.76c.018.001.03-.005.034-.014L.19-.853a.702.702 0 0 1-.124.267.139.139 0 0 1-.033.013l.058.136a.054.054 0 0 0 .026-.024c.036-.015.07-.031.1-.045a.654.654 0 0 1-.145.164.155.155 0 0 1-.039.014L.08-.19a.061.061 0 0 0 .028-.021c.085-.034.16-.069.214-.096a.23.23 0 0 1 .006.052c.076.07.167-.047.044-.134a.732.732 0 0 0 .323-.362c.04.136.11.274.199.359A.178.178 0 0 1 .978-.52L.98-.534a.59.59 0 0 1-.272-.248l.001-.002Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 777.924 1243.95)"
    />
    <path
      d="M.763-.34v.31H.567v-.401h.148v.059H.74c.053 0 .115-.022.115-.03v-.309c.024-.004.031-.013.032-.025L.715-.751v.292H.567v-.343c.027-.004.034-.014.036-.029L.42-.848v.389H.28v-.253c.024-.004.033-.012.035-.023L.144-.752v.279a.202.202 0 0 0-.033.032l.136.077.04-.067H.42v.401H.234v-.271c.024-.004.033-.012.035-.023L.095-.341v.296a.342.342 0 0 0-.033.033l.139.078.04-.068h.522v.087h.025C.841.085.903.062.903.053V-.3c.024-.004.031-.013.032-.025L.763-.34Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 777.924 1394.827)"
    />
    <path
      d="M245.73 1097.47c-56.669-6.58-97.981-27.51-102.569-51.96-2.957-15.77.796-68.445 6.432-90.278 16.701-64.698 57.541-111.787 113.289-130.623 16.435-5.553 52.962-5.586 71.106-.062 44.555 13.562 69.839 37.491 81.375 77.015 5.507 18.87 5.086 53.607-.952 78.524-6.489 26.774-6.146 35.414 1.886 47.494 7.306 10.99 8.09 17.01 3.399 26.08-16.776 32.45-97.279 52.72-173.966 43.81Z"
      style={{
        fill: "#f9c01c",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M234.03 890.678c-10.832-4.361-12.244-22.153-2.531-31.867 9.981-9.98 21.964-8.667 27.536 3.018 2.852 5.981 2.898 7.426.456 14.434-1.735 4.981-4.998 9.323-9.05 12.045-6.762 4.543-9.894 4.995-16.411 2.37Z"
      style={{
        fill: "#423c2b",
      }}
    />
    <path
      d="M263.535 946.739c-9.315-2.338-20.843-11.242-25.016-19.32-4.57-8.846-6.166-21.829-3.334-27.121 2.83-5.288 8.083-5.075 22.67.919 9.162 3.765 16.288 5.14 30.318 5.851 16.924.859 18.492 1.264 20.309 5.251 2.869 6.298-5.969 23.689-15.117 29.743-7.682 5.083-20.319 7.064-29.83 4.677Z"
      style={{
        fill: "#e36d2d",
      }}
    />
    <path
      d="M312.466 904.762c-17.086-11.968-3.359-44.814 15.92-38.094 7.559 2.636 11.382 9.183 11.382 19.491 0 7.344-.954 9.598-6.341 14.985-6.983 6.983-14.343 8.253-20.961 3.618Z"
      style={{
        fill: "#423c2b",
      }}
    />
    <path
      d="M.532-.539c.039-.06.072-.123.097-.185H.94c.016 0 .027-.005.03-.016C.914-.787.821-.857.821-.857l-.083.105H.035l.008.028h.394a1.298 1.298 0 0 1-.415.622l.006.008c.153-.074.287-.18.394-.301v.49H.45c.053 0 .118-.022.12-.03v-.559c.02-.003.028-.01.031-.019L.532-.539Zm.061.077c.094.073.203.189.256.292.157.077.226-.232-.248-.299l-.008.007Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.876 575.291 640.444)"
    />
    <path
      d="m.029-.418.009.032h.91c.017 0 .029-.005.032-.016C.92-.459.816-.55.816-.55l-.093.132H.029Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.876 575.291 791.32)"
    />
    <path
      d="M.399-.677h.195v.074h-.17l.008.028h.162v.085H.363l.008.028h.586c.014 0 .025-.005.028-.016C.941-.517.869-.573.869-.573L.805-.49H.732v-.085h.186c.014 0 .024-.005.027-.016C.905-.626.839-.674.839-.674l-.057.071h-.05v-.074h.214c.014 0 .024-.005.027-.016C.931-.728.864-.777.864-.777l-.059.072H.736c.048-.03.099-.065.131-.093.023.001.034-.008.037-.02l-.17-.041a1.74 1.74 0 0 1-.039.154h-.09C.66-.736.659-.845.467-.851l-.008.006a.292.292 0 0 1 .069.133.075.075 0 0 0 .012.007H.391l.008.028Zm-.121.105h.121c.014 0 .024-.005.027-.016a1.883 1.883 0 0 0-.095-.097l-.053.083v-.206c.028-.004.035-.014.037-.029L.143-.853V-.6H.018l.008.028h.111a1.209 1.209 0 0 1-.115.42l.012.01a.732.732 0 0 0 .109-.121v.357H.17C.222.094.278.068.278.057v-.505a.359.359 0 0 1 .024.143c.089.09.213-.089-.024-.171v-.096Zm.319.522c0 .013-.004.02-.02.02L.485-.035a.354.354 0 0 0 .097-.144l.015-.002v.131Zm.252-.303c-.014.039-.05.11-.078.159a.25.25 0 0 1-.037-.06.071.071 0 0 0 .041-.022L.734-.307C.82-.309.819-.491.514-.436l-.003.013a.595.595 0 0 1 .129.056l-.043.049H.441l.005.028h.151v.08l-.09-.071-.061.062H.343l.009.028h.099a.375.375 0 0 1-.148.243l.006.012A.5.5 0 0 0 .468-.02c.039.009.055.02.069.035.018.017.022.045.026.083.149-.012.17-.057.17-.135v-.162c.027.127.072.211.159.27.014-.07.048-.114.096-.129l.002-.01A.397.397 0 0 1 .784-.18a2.99 2.99 0 0 0 .161-.069c.024.005.034 0 .039-.01L.849-.353Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.876 575.291 942.197)"
    />
    <path
      d="M.196-.089v-.262h.123v.262H.196Zm.123-.538v.248H.196v-.248h.123ZM.86-.735l-.075.076H.66a.992.992 0 0 0 .057-.118c.023 0 .036-.008.04-.021L.571-.852c-.022.147-.07.308-.117.414v-.167c.02-.005.033-.013.04-.022L.371-.724l-.062.069H.243l.111-.124c.024 0 .038-.007.043-.025L.204-.85c-.003.057-.007.136-.012.192L.066-.71v.764h.021C.145.054.196.023.196.008v-.069h.123v.079h.022c.047 0 .112-.028.113-.037v-.405L.46-.42a.772.772 0 0 0 .183-.211h.154c-.006.34-.015.524-.051.556a.05.05 0 0 1-.037.013C.683-.062.615-.066.567-.07l-.001.012c.052.011.089.029.108.051.018.02.024.053.024.1.075 0 .121-.017.16-.056.059-.061.071-.223.078-.643C.96-.61.973-.617.981-.627L.86-.735Zm-.342.284a.436.436 0 0 1 .066.206c.123.107.258-.138-.058-.211l-.008.005Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.876 575.291 1093.073)"
    />
    <path
      d="M.595-.314a.63.63 0 0 0 .038-.073.042.042 0 0 0 .027-.006v.079H.595Zm-.264.057V-.4h.032v.143H.331ZM.206-.4h.031v.143H.201c.004-.046.005-.09.005-.13V-.4Zm.081-.309a.826.826 0 0 1-.04.112H.224L.162-.619a.646.646 0 0 0 .072-.09h.053Zm-.081.14h.031v.141H.206v-.141Zm.157 0v.141H.331v-.141h.032Zm.467.421H.795v-.138h.149c.014 0 .024-.005.027-.016C.933-.337.869-.388.869-.388l-.056.074H.795v-.114c.024-.003.031-.013.033-.025L.66-.469v.058L.526-.455a1.212 1.212 0 0 1-.047.249v-.238a.4.4 0 0 0 .256-.299h.068c-.004.091-.01.133-.021.142-.005.005-.01.006-.023.006a.919.919 0 0 1-.071-.003v.012c.03.008.045.019.057.036.012.016.014.047.014.082.05 0 .083-.009.111-.027.043-.028.055-.083.061-.228.019-.003.03-.009.037-.017L.857-.83l-.063.059H.467l.009.028h.113a.408.408 0 0 1-.11.281v-.085C.501-.552.516-.561.523-.57L.406-.66l-.054.063H.277a.986.986 0 0 0 .136-.096c.02-.001.031-.004.039-.012L.344-.799l-.061.062H.252l.028-.05c.023.001.035-.008.039-.021l-.161-.05c-.027.126-.08.252-.134.332l.011.008a.435.435 0 0 0 .057-.038v.169c0 .152-.002.328-.069.467l.01.008a.465.465 0 0 0 .165-.317h.165v.169c0 .014-.004.02-.02.02-.02 0-.114-.006-.114-.006v.014c.046.008.066.021.081.04.014.017.019.046.022.085.13-.012.147-.058.147-.139v-.146l.009.005a.429.429 0 0 0 .09-.099H.66v.138H.482L.49-.12h.17v.215h.025c.05 0 .11-.026.11-.036V-.12h.174c.014 0 .024-.005.027-.016a1.985 1.985 0 0 0-.107-.093L.83-.148Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.876 575.291 1243.949)"
    />
    <path
      d="M.358-.011v-.186h.269v.186H.358Zm.259-.214H.365L.22-.28v.376h.019c.057 0 .119-.03.119-.043V.017h.269v.07h.025C.697.087.768.063.769.056v-.233a.063.063 0 0 0 .036-.02L.678-.291l-.061.066Zm-.094-.12H.315l.008.028H.66c.014 0 .025-.005.028-.016C.648-.369.581-.42.581-.42l-.058.075Zm.272-.4H.677C.686-.756.693-.768.701-.78c.023.001.037-.007.041-.019L.565-.863a.852.852 0 0 1-.034.125C.492-.774.434-.822.434-.822l-.056.077H.292l.024-.041c.023 0 .036-.009.04-.022L.172-.861a1.017 1.017 0 0 1-.157.359l.01.008a.682.682 0 0 0 .227-.192c.013.035.02.083.015.126.085.091.222-.073-.005-.14l.012-.017h.235A.033.033 0 0 0 .524-.72a.705.705 0 0 1-.061.126L.418-.605c-.036.102-.2.25-.368.326l.005.011c.195-.044.398-.15.488-.257.062.11.187.183.332.222.005-.05.036-.11.09-.129v-.016c-.13 0-.316-.021-.408-.088.035-.003.046-.01.05-.023L.495-.586a.553.553 0 0 0 .161-.131h.286c.015 0 .025-.005.028-.016a2.196 2.196 0 0 0-.113-.092l-.062.08Zm-.142.061c.026.031.05.082.051.128.103.08.215-.117-.043-.133l-.008.005Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.876 575.291 1394.826)"
    />
  </svg>
);
export const Page4V = memo(SvgComponent);
