import * as React from "react";

import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  main: {
    display: "inline-block",
    maxHeight: "1em",
  },
  sub: {
    verticalAlign: "-0.5ex",
    marginLeft: "-0.1667em",
    marginRight: "-0.125em",
    fontSize: "1em",
    textTransform: "uppercase",
  },
  sup: {
    fontSize: "0.85em",
    verticalAlign: "0.15em",
    marginLeft: "-0.36em",
    marginRight: "-0.15em",
    textTransform: "uppercase",
  },
});

export const LaTeX: React.FC = () => {
  const styles = useStyles();
  return (
    <span className={styles.main}>
      L<sup className={styles.sup}>a</sup>T<sub className={styles.sub}>e</sub>X
    </span>
  );
};
