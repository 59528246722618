import {
  Theme,
  BrandVariants,
  createDarkTheme,
  createLightTheme,
} from "@fluentui/react-components";

const survivalTheme: BrandVariants = {
  10: "#030403",
  20: "#151A17",
  30: "#212C25",
  40: "#2A3930",
  50: "#33463B",
  60: "#3C5346",
  70: "#466151",
  80: "#4F705D",
  90: "#597E68",
  100: "#638D75",
  110: "#6D9D81",
  120: "#7FAB91",
  130: "#93B8A2",
  140: "#A8C5B3",
  150: "#BCD3C5",
  160: "#D1E1D7",
};

export const survivalLightTheme: Theme = {
  ...createLightTheme(survivalTheme),
};

export const survivalDarkTheme: Theme = {
  ...createDarkTheme(survivalTheme),
};

Object.entries(survivalLightTheme).forEach(([key, value]) => {
  if (value === '#ffffff') {
    (survivalLightTheme as any)[key] = '#ffefd6';
  }
});

Object.entries(survivalDarkTheme).forEach(([key, value]) => {
  if (value === '#ffffff') {
    (survivalDarkTheme as any)[key] = '#ffefd6';
  }
});

survivalDarkTheme.colorBrandForeground1 = survivalTheme[110];
survivalDarkTheme.colorBrandForeground2 = survivalTheme[120];