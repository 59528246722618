import * as React from "react";

import {
  makeStyles,
  shorthands,
  mergeClasses,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  coverImage: {
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundSize: "cover",
    transitionDuration: "300ms",
    transitionProperty: "transform, box-shadow",
    transitionTimingFunction: "ease-out",
    transform: "rotate3d(0)",
    ...shorthands.overflow("hidden"),
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    position: "relative",

    "::after": {
      content: "''",
      display: "block",
      paddingBottom: "100%",
    },
  },
  glow: {
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    position: "absolute",

    backgroundImage:
      "radial-gradient(circle at 50% -20%, #ffffff22, #0000000f)",
  },
});

export interface IRotatingImageProps {
  src?: string;
  className?: string;
  cardStyle: React.CSSProperties;
  glowStyle: React.CSSProperties;
}

export const useRotateImage = () => {
  const containerRef = React.useRef<HTMLElement>(null);

  const [cardTransform, setCardTransform] = React.useState("");
  const [glowBackgroundImage, setGlowBackgroundImage] = React.useState("");

  const [mouseInHeader, setMouseInHeader] = React.useState(false);

  const handleMouseEnterContainer = React.useCallback(() => {
    setMouseInHeader(true);
  }, []);
  const handleMouseLeaveContainer = React.useCallback(() => {
    setMouseInHeader(false);
  }, []);

  const handleMouseMoveForDocument = React.useCallback((event: MouseEvent) => {
    const bounds = containerRef.current?.getBoundingClientRect();

    if (!bounds) return;

    const mouseX = event.clientX;
    const mouseY = event.clientY;
    const leftX = mouseX - bounds.x;
    const topY = mouseY - bounds.y;
    const center = {
      x: leftX - bounds.width / 2,
      y: topY - bounds.height / 2,
    };
    const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

    setCardTransform(`
      scale3d(1.03, 1.03, 1.03)
      rotate3d(
        ${center.y / 100},
        ${-center.x / 100},
        0,
        ${Math.log(distance)}deg
      )
    `);

    setGlowBackgroundImage(`
      radial-gradient(
        circle at
        ${center.x + bounds.width / 2}px
        ${center.y + bounds.height / 2}px,
        #ffffff22,
        #0000000f
      )
    `);
  }, []);

  React.useEffect(() => {
    if (mouseInHeader) {
      document.addEventListener("mousemove", handleMouseMoveForDocument);
    } else {
      document.removeEventListener("mousemove", handleMouseMoveForDocument);
      setCardTransform("");
      setGlowBackgroundImage("");
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMoveForDocument);
    };
  }, [handleMouseMoveForDocument, mouseInHeader]);

  const cardStyle = React.useMemo(
    () => ({ transform: cardTransform }),
    [cardTransform]
  );
  const glowStyle = React.useMemo(
    () => ({ backgroundImage: glowBackgroundImage }),
    [glowBackgroundImage]
  );

  return {
    containerRef,
    handleMouseEnterContainer,
    handleMouseLeaveContainer,
    cardStyle,
    glowStyle,
  };
};

export const RotatingImage: React.FC<
  React.PropsWithChildren<IRotatingImageProps>
> = ({ src, className, cardStyle, glowStyle, children }) => {
  const styles = useStyles();

  const trueCardStyle = React.useMemo(
    () => ({
      backgroundImage: `url(${src})`,
      ...cardStyle,
    }),
    [cardStyle, src]
  );

  return (
    <div
      className={mergeClasses(styles.coverImage, className)}
      style={trueCardStyle}
    >
      {children}
      <div className={styles.glow} style={glowStyle}></div>
    </div>
  );
};
