import * as React from "react";

import {
  makeStyles,
  tokens,
  shorthands,
  mergeClasses,
} from "@fluentui/react-components";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  main: {
    backgroundColor: tokens.colorNeutralForeground1,
    color: tokens.colorNeutralBackground1,
    lineHeight: "1.8em",
    ...shorthands.padding("0.5em"),
    ...shorthands.margin("0.5em"),

    "@media (min-width: 768px)": {
      fontSize: `calc(1rem + ((1vw - 7.68px) * 0.5208))`,
    },

    "@media (min-width: 1920px)": {
      fontSize: "24px",
    },

    "@media screen and (max-width: 1200px) and (min-width: 600px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  coverContainer: {
    fontSize: 0,
    lineHeight: 0,
    "@media screen and (max-width: 1200px) and (min-width: 600px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  cover: {
    display: "block",
    maxWidth: "100%",
    "@media screen and (max-width: 1200px) and (min-width: 600px)": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  contentContainer: {
    boxSizing: "border-box",
    paddingTop: "12px",

    "@media screen and (max-width: 1200px) and (min-width: 600px)": {
      paddingTop: 0,
      paddingLeft: "12px",
      display: "flex",
      flexDirection: "column",
      alignItems: "space-between",
    },
  },
  content: {
    ...shorthands.padding("12px"),
  },
  linkList: {
    ...shorthands.padding("0"),
    marginBottom: "-12px",
  },
  linkItem: {
    listStyleType: "none",
  },
  link: {
    width: "100%",
    ...shorthands.margin("12px", "0"),
    ...shorthands.borderWidth("0"),
    color: tokens.colorNeutralForeground1,
    backgroundColor: tokens.colorBrandBackground2,
    fontSize: "1.2em",
    textAlign: "center",
    boxSizing: "border-box",
    display: "block",

    ":link": {
      color: tokens.colorNeutralForeground1,
      textDecorationColor: "transparent",
    },

    "> ax-reveal": {
      width: "100%",
      height: "100%",
      boxSizing: "border-box",
      ...shorthands.padding("0.5em"),
      boxShadow: "inset 0 0 0 1px #839583",
      "--reveal-color": "#e0ffe4",
      "--reveal-opacity": "0.4",
      "--reveal-border-width": "2px",
    },

    "@media screen and (max-width: 1200px) and (min-width: 600px)": {
      fontSize: "1em",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.2em",
    lineHeight: "2em",
  },
  primaryLink: {
    backgroundColor: tokens.colorBrandBackground,
  },
  disabledLink: {},
  flex1: {
    flexBasis: "0%",
    flexGrow: "1",
    flexShrink: "1",
  },
});

export interface IProductLink {
  title: string;
  to: string;
  isPrimary?: boolean;
  disabled?: boolean;
}

export interface IProductCard {
  cover: string;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  className?: string;
  links: IProductLink[];
}

export const ProductCard: React.FC<IProductCard> = ({
  cover,
  title,
  description,
  className,
  links,
}) => {
  const styles = useStyles();
  return (
    <div className={mergeClasses(styles.main, className)}>
      <div className={mergeClasses(styles.flex1, styles.coverContainer)}>
        <img className={styles.cover} src={cover} alt="商品封面" />
      </div>
      <div className={mergeClasses(styles.flex1, styles.contentContainer)}>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <div>{description}</div>
        </div>
        <ul className={styles.linkList}>
          {links.map((x, index) => (
            <li className={styles.linkItem} key={index}>
              <Link
                to={x.to}
                className={mergeClasses(
                  styles.link,
                  x.isPrimary && styles.primaryLink,
                  x.disabled && styles.disabledLink
                )}
              >
                <ax-reveal>{x.title}</ax-reveal>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
