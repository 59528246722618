import { IProductCard } from "../components/ProductCard";

import bookCover from "../assets/book.webp";
import audioBookCover from "../assets/audioBook.webp";
import appCover from "../assets/app.webp";

export const bookProduct: IProductCard = {
  cover: bookCover,
  title: "當代學生生存手冊 · 文字版",
  description:
    "通過一本簡單的小冊子，帶你解開一層層的困惑，重新理解學校、教育和我們的未來。",
  links: [
    {
      title: "電子書",
      to: "https://m-b.booth.pm/items/5322274",
      isPrimary: true,
    },
    {
      title: "印刷授權",
      to: "/pyo",
    },
  ],
};

export const audioBookProduct: IProductCard = {
  cover: audioBookCover,
  title: "當代學生生存手冊 · 有聲書",
  description:
    "為不願閱讀文字的受眾準備的有聲書籍，幫助你利用零碎的時間吸收知識，瞭解自己。",
  links: [
    {
      title: "MP3 版",
      to: "https://player.soundon.fm/p/5560d87d-e30b-4bb4-9fd0-09f2da638706",
      isPrimary: true,
      disabled: false,
    },
    {
      title: "無損版（製作中）",
      to: "#",
      disabled: true,
    },
  ],
};

export const appProduct: IProductCard = {
  cover: appCover,
  title: "配套交互軟體 · Oasis",
  description:
    "帶你一起重新理解自己的情緒，記錄每日的生活的變化和流動，發現自己關心的價值。",
  links: [
    {
      title: "在線版（製作中）",
      to: "#",
      isPrimary: true,
      disabled: true,
    },
    {
      title: "獨立版（製作中）",
      to: "#",
      disabled: true,
    },
  ],
};
