import { IProductCard } from "../components/ProductCard";

import pdfCover from "../assets/pdf.webp";
import latexCover from "../assets/latex.webp";
import { LaTeX } from "../components/LaTeX";

export const pdfProduct: IProductCard = {
  cover: pdfCover,
  title: "PDF 版本",
  description: "已經編譯好的稿件，可供直接印刷。",
  links: [
    {
      title: "購買授權",
      to: "#",
      isPrimary: true,
    },
  ],
};

export const latexFile: IProductCard = {
  cover: latexCover,
  title: (
    <>
      <LaTeX /> 工程
    </>
  ),
  description: "可供自由調整版式的原始工程檔案。",
  links: [
    {
      title: "購買授權",
      to: "#",
      isPrimary: true,
      disabled: true,
    },
  ],
};
