import * as React from "react";
import { SVGProps, memo } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
      fill: "currentcolor",
    }}
    viewBox="0 0 1080 1920"
    {...props}
  >
    <path
      d="M287.54 1097.47c-56.668-6.58-97.981-27.51-102.568-51.96-2.958-15.77.795-68.445 6.431-90.278 16.702-64.698 57.542-111.787 113.29-130.623 16.434-5.553 52.962-5.586 71.105-.062 44.555 13.562 69.839 37.491 81.375 77.015 5.508 18.87 5.087 53.607-.951 78.524-6.489 26.774-6.146 35.414 1.885 47.494 7.306 10.99 8.091 17.01 3.4 26.08-16.777 32.45-97.279 52.72-173.967 43.81Z"
      style={{
        fill: "#f9c01c",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M288.928 973.338c-3.974-4.85-6.337-11.445-3.919-20.427 3.832-14.229 18.162-20.134 25.038-10.316 8.136 11.615.461 34.578-11.557 34.578-3.448 0-7.8-1.684-9.562-3.835Zm59.656-72.384c-8.183-6.626-7.398-18.91 1.729-27.065 12.887-11.515 28.895-5.964 28.895 10.018 0 15.755-19.093 26.384-30.624 17.047Zm-75.696-15.858c-8.017-8.858-2.719-26.278 9.393-30.883 16.761-6.372 26.443 16.069 13.101 30.364-5.888 6.308-17.023 6.565-22.494.519Zm85.839-28.087c-3.961-4.378-4.802-9.142-1.613-9.142 1.108 0 4.806 1.44 8.219 3.2 3.413 1.761 8.917 3.752 12.232 4.425 9.81 1.993 6.875 5.525-4.592 5.525-7.504 0-11.682-1.176-14.246-4.008Zm-80.334-9.341c-7.146-2.97-3.166-5.264 12.602-7.261 10.587-1.341 11.506-1.102 11.506 3.001 0 2.452-1.435 3.953-3.014 4.459-1.973.632-5.912 1.1-9.314 1.04-3.512-.063-8.767.014-11.78-1.239Z"
      style={{
        fill: "#423c2b",
        fillRule: "nonzero",
      }}
    />
    <path
      d="M.74-.726v.252H.536v-.252H.74Zm-.204.53v-.25H.74v.25H.536Zm0 .028H.74v.057h.024c.05 0 .119-.029.121-.038v-.554a.07.07 0 0 0 .039-.022L.795-.826.73-.754H.542L.395-.813v.727h.022c.062 0 .119-.033.119-.049v-.033ZM.19-.856c-.035.199-.108.42-.176.558l.011.007a.888.888 0 0 0 .11-.111v.495h.027c.057 0 .116-.03.118-.04v-.572c.019-.004.028-.011.031-.02L.246-.563C.283-.628.316-.7.345-.779c.024 0 .037-.008.041-.021L.19-.856Zm.587.864H.326l.008.028h.633C.982.036.993.031.996.02a3.538 3.538 0 0 0-.14-.121L.777.008Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 604.914 420.008)"
    />
    <path
      d="M.507-.85c.032.035.06.092.065.145.123.087.242-.146-.058-.15L.507-.85Zm.445.195c.014 0 .025-.005.028-.016C.933-.711.856-.767.856-.767l-.069.084H.341l.008.028h.603Zm-.546.199.008.028h.451c.014 0 .025-.005.028-.016C.846-.483.77-.539.77-.539l-.067.083H.406Zm.297-.111H.406l.008.028h.451c.014 0 .025-.005.028-.016C.846-.594.77-.65.77-.65l-.067.083ZM.064-.831c.038.038.077.1.089.158.135.082.24-.173-.083-.163l-.006.005Zm.662.517v.163H.541v-.163h.185ZM.401-.332v.254H.42c.058 0 .121-.03.121-.042v-.003h.185v.042h.025c.044 0 .116-.022.117-.029v-.181c.021-.005.034-.014.04-.022L.778-.41l-.062.068h-.17L.401-.397v.03L.309-.439.24-.371H.223l.096-.151c.022-.002.041-.007.05-.017L.255-.643l-.058.058H.041l.009.028h.146a1.861 1.861 0 0 1-.097.158.212.212 0 0 0-.04.021l.112.074.04-.039h.033a.784.784 0 0 1-.092.222c-.036.017-.089.047-.129.064L.115.08C.122.076.127.07.125.06c.02-.055.04-.107.055-.139C.189-.1.201-.102.216-.081.299.023.384.07.607.07h.278c.007-.055.04-.109.097-.122v-.011c-.115.007-.21.008-.325.008-.23.001-.354-.014-.429-.067L.213-.13a.556.556 0 0 0 .18-.201l.008-.001Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 604.914 570.882)"
    />
    <path
      d="m.029-.418.009.032h.91c.017 0 .029-.005.032-.016C.92-.459.816-.55.816-.55l-.093.132H.029Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 604.914 721.76)"
    />
    <path
      d="M.192-.017a.094.094 0 0 0 .023-.025c.116-.101.199-.193.242-.244L.453-.294.29-.229v-.265l.17-.032c.013-.002.023-.009.023-.021-.045-.029-.119-.07-.119-.07l-.042.091L.29-.52v-.262c.027-.004.035-.014.038-.029L.139-.829v.337l-.123.023.012.024.111-.021v.23c0 .027-.008.039-.057.071l.11.148Zm.665-.796-.08.081H.366l.009.028h.152c-.011.4-.04.647-.339.787l.009.014c.415-.114.459-.354.481-.801H.79c-.007.41-.016.598-.056.634a.051.051 0 0 1-.037.014c-.024 0-.075-.003-.111-.006v.011c.045.012.072.027.089.051.015.019.018.049.018.092.068 0 .121-.011.161-.053.063-.065.073-.207.081-.716.024-.004.038-.012.047-.022L.857-.813Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 604.914 872.637)"
    />
    <path
      d="M.663-.75v.104H.341V-.75h.322Zm-.322.238v-.106h.322v.106H.341Zm0 .028h.322v.055H.69a.292.292 0 0 0 .127-.032v-.265a.062.062 0 0 0 .039-.022L.719-.851l-.066.073H.349L.188-.839v.43H.21c.063 0 .131-.033.131-.047v-.028Zm.248.292h.27c.015 0 .027-.005.03-.016-.052-.047-.14-.117-.14-.117L.672-.22H.589v-.137h.357c.015 0 .026-.005.029-.016-.047-.044-.127-.11-.127-.11l-.071.098h-.75l.008.028h.4v.294a.207.207 0 0 1-.116-.083.474.474 0 0 0 .041-.11c.026 0 .036-.01.039-.025L.205-.314a.515.515 0 0 1-.192.398l.006.009a.517.517 0 0 0 .285-.212c.052.156.149.197.324.197h.283c0-.054.02-.103.064-.114v-.011C.894-.045.706-.045.63-.045H.589v-.147Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 604.914 1023.515)"
    />
    <path
      d="M.035.097C.181.08.33.039.42-.009c.035.008.056.003.066-.009L.328-.123a.925.925 0 0 1-.296.21l.003.01Zm.544-.182A.52.52 0 0 1 .81.06c.116.1.355-.149-.228-.156l-.003.011ZM.348-.239h.3v.087h-.3v-.087Zm.3-.34v.09h-.3v-.09h.3Zm0 .228v.084h-.3v-.084h.3Zm0-.028h-.3v-.082h.3v.082ZM.8-.194v-.371C.827-.57.838-.576.845-.587L.699-.685l-.061.078h-.09a2.25 2.25 0 0 0 .016-.094h.341c.015 0 .027-.005.03-.016C.882-.76.797-.823.797-.823l-.076.094H.569l.012-.082C.604-.815.618-.826.62-.843L.431-.86.43-.729H.069l.008.028h.352l-.002.094H.356L.203-.664v.512H.037l.008.028h.906c.014 0 .026-.005.029-.016C.93-.186.844-.254.844-.254L.8-.194Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 604.914 1174.392)"
    />
    <path
      d="M.196-.089v-.262h.123v.262H.196Zm.123-.538v.248H.196v-.248h.123ZM.86-.735l-.075.076H.66a.992.992 0 0 0 .057-.118c.023 0 .036-.008.04-.021L.571-.852c-.022.147-.07.308-.117.414v-.167c.02-.005.033-.013.04-.022L.371-.724l-.062.069H.243l.111-.124c.024 0 .038-.007.043-.025L.204-.85c-.003.057-.007.136-.012.192L.066-.71v.764h.021C.145.054.196.023.196.008v-.069h.123v.079h.022c.047 0 .112-.028.113-.037v-.405L.46-.42a.772.772 0 0 0 .183-.211h.154c-.006.34-.015.524-.051.556a.05.05 0 0 1-.037.013C.683-.062.615-.066.567-.07l-.001.012c.052.011.089.029.108.051.018.02.024.053.024.1.075 0 .121-.017.16-.056.059-.061.071-.223.078-.643C.96-.61.973-.617.981-.627L.86-.735Zm-.342.284a.436.436 0 0 1 .066.206c.123.107.258-.138-.058-.211l-.008.005Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 604.914 1325.27)"
    />
    <path
      d="M.182-.32v-.369h.043v.369H.182Zm.174-.348a.071.071 0 0 0 .04-.021L.274-.783l-.059.066H.186L.056-.77v.612h.019c.056 0 .107-.029.107-.042v-.092h.043v.084h.022c.044 0 .107-.025.109-.033v-.427Zm.035.462a.156.156 0 0 1-.075.136C.283-.053.261-.023.273.016c.016.04.066.051.102.028.051-.032.084-.123.029-.25H.391Zm.08.001a.436.436 0 0 1-.014.177c.069.1.21-.051.026-.18l-.012.003Zm.106-.011a.24.24 0 0 1 .024.147C.678.014.796-.145.588-.221l-.011.005Zm-.035-.22h.07v.123h-.07v-.123Zm.07-.297v.12h-.07v-.12h.07Zm0 .269h-.07v-.121h.07v.121Zm.262.09-.065.061H.742v-.123h.16c.014 0 .024-.005.027-.016C.888-.489.82-.543.82-.543l-.06.079H.742v-.121h.155c.014 0 .025-.005.027-.016a2.228 2.228 0 0 0-.109-.091l-.06.079H.742v-.12H.92c.014 0 .025-.005.028-.016A2.406 2.406 0 0 0 .83-.846l-.066.085H.556L.407-.816v.596h.025c.069 0 .11-.036.11-.047v-.018h.277a2.051 2.051 0 0 1-.007.1C.795-.211.758-.235.689-.245L.68-.239c.029.03.054.081.053.126.026.021.052.019.07.005-.007.038-.015.061-.025.069-.007.006-.015.008-.029.008L.658-.035v.012c.034.009.056.022.069.039.013.017.016.047.016.083A.193.193 0 0 0 .866.066c.05-.037.069-.119.079-.33.021-.003.033-.009.04-.018L.874-.374Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 604.914 1476.14)"
    />
    <path
      d="M.467-.163a.09.09 0 0 0-.09.089c0 .049.039.088.09.088a.088.088 0 0 0 .091-.088.09.09 0 0 0-.091-.089ZM.408-.728a.154.154 0 0 1 .043-.005c.088 0 .144.043.144.126 0 .066-.024.099-.077.147-.06.056-.087.081-.087.128 0 .026.009.071.015.103h.035l.007-.056c.006-.055.034-.07.114-.119.103-.062.141-.108.141-.188 0-.107-.086-.177-.249-.177-.143 0-.25.069-.252.198a.08.08 0 0 0 .067.033c.047 0 .074-.022.084-.097l.015-.093Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="matrix(136.087 0 0 150.87612 604.914 1627.018)"
    />
  </svg>
);
export const Page3V = memo(SvgComponent);
